import React, { useEffect, useState } from "react";
import "./Viewinventory.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faUserFriends,
  faCube,
} from "@fortawesome/free-solid-svg-icons";

const Dashboard = ({ enquiryId }) => {
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [customItems, setCustomItems] = useState({});
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [rateData, setRateData] = useState([]);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [moversCount, setMoversCount] = useState(1);
  const [totalVolume, setTotalVolume] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [pickupFlights, setPickupFlight] = useState("");
  const [dropOffFlights, setDropOffFlight] = useState("");
  const [setDuration, setTotalDuration] = useState("");
  const [setBaseTime, setBaseAddressTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchRooms();
    fetchVehicleSizes();
    fetchRateData();
    fetchEnquiry();
  }, []);

  useEffect(() => {
    // Enable the button only if there is some volume to be processed
    setButtonDisabled(totalVolume <= 0);
  }, [totalVolume]);

  useEffect(() => {
    // Clear error message when totalVolume changes
    setErrorMessage("");
  }, [totalVolume]);

  const fetchEnquiry = () => {
    const enquiry_id = localStorage.getItem("enquiry_id");
    fetch(
      `https://demo.webwideit.solutions/cbd-instamove/public/api/enquiry-data/${enquiry_id}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setPickupFlight(data.how_many_flights_of_stairs);
        setDropOffFlight(data.drop_of_how_many_flights_of_stairs);
        setTotalDuration(data.total_duration);
        setBaseAddressTime(data.baseAddress_time);
        console.log(".........pickupFlights", pickupFlights);
        console.log("............dropOffFlights", dropOffFlights);
      });
  };

  const fetchRooms = () => {
    fetch("https://demo.webwideit.solutions/cbd-instamove/public/api/rooms")
      .then((response) => response.json())
      .then((data) => {
        setRooms(data);
        if (data.length > 0) {
          setSelectedRoom(data[0].id);
          fetchCustomItems(data[0].id);
        }
      });
  };

  const fetchCustomItems = (roomId) => {
    fetch(
      `https://demo.webwideit.solutions/cbd-instamove/public/api/custom-items/${roomId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCustomItems((prevItems) => ({
          ...prevItems,
          [roomId]: data,
        }));
      });
  };

  const fetchVehicleSizes = () => {
    fetch(
      "https://demo.webwideit.solutions/cbd-instamove/public/api/vehicle-size"
    )
      .then((response) => response.json())
      .then((data) => {
        const sortedVehicleOptions = data
          .map((vehicle) => ({
            size: parseFloat(vehicle.vehicle_size),
            name: vehicle.vehicle_type,
          }))
          .sort((a, b) => a.size - b.size);
        setVehicleOptions(sortedVehicleOptions);
      });
  };

  const fetchRateData = () => {
    fetch("https://demo.webwideit.solutions/cbd-instamove/public/api/rate-data")
      .then((response) => response.json())
      .then((data) => {
        const formattedRateData = data.map((rate) => ({
          cubic_meter_size_start: parseFloat(rate.cubic_meter_size_start),
          cubic_meter_size_end: parseFloat(rate.cubic_meter_size_end),
          time_required: parseFloat(rate.time_required),
          movers_needed: parseInt(rate.movers_needed),
          per_hour_rate: parseFloat(rate.per_hour_rate),
          loading_high_time: rate.loading_high_time,
          loading_medium_time: rate.loading_medium_time,
          loading_low_time: rate.loading_low_time,
          unloading_high_time: rate.unloading_high_time,
          unloading_medium_time: rate.unloading_medium_time,
          unloading_low_time: rate.unloading_low_time,
          booking_fee: rate.booking_fee,
          loading_oneflight: rate.loading_oneflight,
          loading_secondflight: rate.loading_secondflight,
          loading_thirdflight: rate.loading_thirdflight,
          unloading_oneflight: rate.unloading_oneflight,
          unloading_secondflight: rate.unloading_secondflight,
          unloading_thirdflight: rate.unloading_thirdflight,
          assembly_disassembly_per_hour_rate:rate.assembly_disassembly_per_hour_rate,
        }));
        setRateData(formattedRateData);
      });
  };

 
  const showRoomDetails = (roomId) => {
    setSelectedRoom(roomId);
    fetchCustomItems(roomId);
  };

  const updateQuantity = (roomId, itemId, delta) => {
    setCustomItems((prevItems) => {
      const updatedItems = { ...prevItems };
      const roomItems = updatedItems[roomId] || [];
      const updatedRoomItems = roomItems.map((customItem) => ({
        ...customItem,
        items: customItem.items.map((item) =>
          item.id === itemId
            ? { ...item, quantity: Math.max((item.quantity || 0) + delta, 0) }
            : item
        ),
      }));
      updatedItems[roomId] = updatedRoomItems;
      return updatedItems;
    });
  };

  const incrementQuantity = (roomId, itemId) => {
    updateQuantity(roomId, itemId, 1);
  };

  const decrementQuantity = (roomId, itemId) => {
    updateQuantity(roomId, itemId, -1);
  };

  const calculateTotalVolume = () => {
    let volume = 0;
    Object.values(customItems).forEach((roomItems) => {
      roomItems.forEach((customItem) => {
        customItem.items.forEach((item) => {
          volume += (item.quantity || 0) * parseFloat(item.item_size);
        });
      });
    });
    setTotalVolume(volume);
  };

  const calculateTotalVehicles = () => {
    const { requiredVehicles } = getRequiredVehicles(totalVolume);
    setTotalVehicles(requiredVehicles);
    determineMoversNeeded(totalVolume);
  };

  const getRequiredVehicles = (totalVolume) => {
    let remainingVolume = totalVolume;
    let truckNames = [];
    let totalVehicles = 0;

    for (const vehicle of vehicleOptions) {
      if (remainingVolume <= 0) break;

      const truckSize = vehicle.size;
      if (remainingVolume <= truckSize) {
        truckNames.push(`${vehicle.name} (x1)`);
        totalVehicles += 1;
        remainingVolume = 0;
        break;
      }
    }

    if (remainingVolume > 0) {
      for (const vehicle of vehicleOptions.reverse()) {
        if (remainingVolume <= 0) break;

        const truckSize = vehicle.size;
        const count = Math.ceil(remainingVolume / truckSize);
        remainingVolume -= count * truckSize;
        totalVehicles += count;
        truckNames.push(`${vehicle.name} (x${count})`);
      }
    }

    return { requiredVehicles: totalVehicles, truckNames };
  };

  const determineMoversNeeded = (cubicSize) => {
    const rate = rateData.find(
      (rate) =>
        rate.cubic_meter_size_start <= cubicSize &&
        cubicSize <= rate.cubic_meter_size_end
    );
    setMoversCount(rate ? rate.movers_needed : 1);
  };

  const timeStringToMinutes = (timeString) => {
    if (typeof timeString === "number") {
      return timeString * 60;
    }
    if (typeof timeString === "string") {
      const [hoursStr, minutesStr] = timeString.split(":");
      const hours = parseFloat(hoursStr) || 0;
      const minutes = parseFloat(minutesStr) || 0;
      return hours * 60 + minutes;
    }
    console.warn("Invalid time format:", timeString);
    return 0;
  };

  const convertToMinutes = (timeString) => {
    if (!timeString || typeof timeString !== "string") {
      console.warn("Invalid time format:", timeString);
      return 0;
    }

    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const minutesToTimeString = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  const calculateEstimatedTimeAndCost = (
    totalSelectedVolume,
    pickupFlights = [],
    dropOffFlights = []
  ) => {
    const rate = rateData.find(
      (rate) =>
        rate.cubic_meter_size_start <= totalSelectedVolume &&
        totalSelectedVolume <= rate.cubic_meter_size_end
    );

    if (!rate) {
      return {
        estimatedTime: 0,
        totalCost: 0,
        moversCount: 0,
        totalLoadingLow: 0,
        totalLoadingMedium: 0,
        totalLoadingHigh: 0,
        totalUnloadingLow: 0,
        totalUnloadingMedium: 0,
        totalUnloadingHigh: 0,
        loadingLowTiming: "00:00",
        loadingMediumTiming: "00:00",
        loadingHighTiming: "00:00",
        unLoadingLowTiming: "00:00",
        unLoadingMediumTiming: "00:00",
        unLoadingHighTiming: "00:00",
        totalDurationCost: 0,
        grandTotalHigh: 0,
        grandTotalMedium: 0,
        grandTotalLow: 0,
        costPerHour: 0,
        bookingfee: 0,
        totalDurationCostBackToBase: 0,
      };
    }

    const estimatedTime = rate.time_required;
    let loadingLowTime = timeStringToMinutes(rate.loading_low_time);
    let loadingMediumTime = timeStringToMinutes(rate.loading_medium_time);
    let loadingHighTime = timeStringToMinutes(rate.loading_high_time);
    let unLoadingLowTime = timeStringToMinutes(rate.unloading_low_time);
    let unLoadingMediumTime = timeStringToMinutes(rate.unloading_medium_time);
    let unLoadingHighTime = timeStringToMinutes(rate.unloading_high_time);
  const assembly_disassembly_per_hour_rate= rate.assembly_disassembly_per_hour_rate;

    const loadingOneFlight = timeStringToMinutes(rate.loading_oneflight);
    const loadingSecondFlight = timeStringToMinutes(rate.loading_secondflight);
    const loadingThirdFlight = timeStringToMinutes(rate.loading_thirdflight);
    const unloadingOneFlight = timeStringToMinutes(rate.unloading_oneflight);
    const unloadingSecondFlight = timeStringToMinutes(
      rate.unloading_secondflight
    );
    const unloadingThirdFlight = timeStringToMinutes(
      rate.unloading_thirdflight
    );

    if (pickupFlights.includes("1 Flight")) {
      loadingLowTime += loadingOneFlight;
      loadingMediumTime += loadingOneFlight;
      loadingHighTime += loadingOneFlight;
    } else if (pickupFlights.includes("2 Flight")) {
      loadingLowTime += loadingSecondFlight;
      loadingMediumTime += loadingSecondFlight;
      loadingHighTime += loadingSecondFlight;
    } else if (pickupFlights.includes("3+ Flight")) {
      loadingLowTime += loadingThirdFlight;
      loadingMediumTime += loadingThirdFlight;
      loadingHighTime += loadingThirdFlight;
    }

    if (dropOffFlights.includes("1 Flight")) {
      unLoadingLowTime += unloadingOneFlight;
      unLoadingMediumTime += unloadingOneFlight;
      unLoadingHighTime += unloadingOneFlight;
    } else if (dropOffFlights.includes("2 Flight")) {
      unLoadingLowTime += unloadingSecondFlight;
      unLoadingMediumTime += unloadingSecondFlight;
      unLoadingHighTime += unloadingSecondFlight;
    } else if (dropOffFlights.includes("3+ Flight")) {
      unLoadingLowTime += unloadingThirdFlight;
      unLoadingMediumTime += unloadingThirdFlight;
      unLoadingHighTime += unloadingThirdFlight;
    }

    const loadingLowTiming = minutesToTimeString(loadingLowTime);
    const loadingMediumTiming = minutesToTimeString(loadingMediumTime);
    const loadingHighTiming = minutesToTimeString(loadingHighTime);
    const unLoadingLowTiming = minutesToTimeString(unLoadingLowTime);
    const unLoadingMediumTiming = minutesToTimeString(unLoadingMediumTime);
    const unLoadingHighTiming = minutesToTimeString(unLoadingHighTime);

    const costPerHour = parseFloat(rate.per_hour_rate) || 0;
    const costPerMinute = costPerHour / 60;
    const totalCost = (estimatedTime * costPerMinute).toFixed(2);
    const totalLoadingLow = (loadingLowTime * costPerMinute).toFixed(2);
    const totalLoadingMedium = (loadingMediumTime * costPerMinute).toFixed(2);
    const totalLoadingHigh = (loadingHighTime * costPerMinute).toFixed(2);
    const totalUnloadingLow = (unLoadingLowTime * costPerMinute).toFixed(2);
    const totalUnloadingMedium = (unLoadingMediumTime * costPerMinute).toFixed(
      2
    );
    const totalUnloadingHigh = (unLoadingHighTime * costPerMinute).toFixed(2);

    const totalMinutes = convertToMinutes(setDuration);
    const totalDurationCost = (totalMinutes * costPerMinute).toFixed(2);

    let totalBackToBaseTimeMinutes = convertToMinutes(setBaseTime);
    if (totalBackToBaseTimeMinutes < 30) {
      totalBackToBaseTimeMinutes = 30;
    }
    const totalDurationCostBackToBase = (
      totalBackToBaseTimeMinutes * costPerMinute
    ).toFixed(2);

    const bookingfee = parseFloat(rate.booking_fee) || 0;

    const grandTotalLow = (
      parseFloat(totalLoadingLow) +
      parseFloat(totalUnloadingLow) +
      parseFloat(totalDurationCost) +
      parseFloat(bookingfee) +
      parseFloat(totalDurationCostBackToBase)
    ).toFixed(2);

    const grandTotalMedium = (
      parseFloat(totalLoadingMedium) +
      parseFloat(totalUnloadingMedium) +
      parseFloat(totalDurationCost) +
      parseFloat(bookingfee) +
      parseFloat(totalDurationCostBackToBase)
    ).toFixed(2);

    const grandTotalHigh = (
      parseFloat(totalLoadingHigh) +
      parseFloat(totalUnloadingHigh) +
      parseFloat(totalDurationCost) +
      parseFloat(bookingfee) +
      parseFloat(totalDurationCostBackToBase)
    ).toFixed(2);

    return {
      estimatedTime,
      totalCost,
      moversCount: rate.movers_needed,
      totalLoadingLow,
      totalLoadingMedium,
      totalLoadingHigh,
      totalUnloadingLow,
      totalUnloadingMedium,
      totalUnloadingHigh,
      loadingLowTiming,
      loadingMediumTiming,
      loadingHighTiming,
      unLoadingLowTiming,
      unLoadingMediumTiming,
      unLoadingHighTiming,
      totalDurationCost,
      grandTotalHigh,
      grandTotalMedium,
      grandTotalLow,
      costPerHour,
      bookingfee,
      totalDurationCostBackToBase,
      assembly_disassembly_per_hour_rate,
    };
  };

  const saveInventory = () => {
    const items = [];
    let totalSelectedVolume = 0;
    const enquiry_id = localStorage.getItem("enquiry_id");

    Object.entries(customItems).forEach(([roomId, roomItems]) => {
      roomItems.forEach((customItem) => {
        customItem.items.forEach((item) => {
          const quantity = item.quantity || 0;
          if (quantity > 0) {
            const itemSize = parseFloat(item.item_size);
            totalSelectedVolume += quantity * itemSize;
            items.push({
              room_id: roomId,
              custom_item_id: customItem.id,
              item_id: item.id,
              quantity,
              enquiry_id,
            });
          }
        });
      });
    });

    const {
      estimatedTime,
      totalCost,
      moversCount,
      totalLoadingLow,
      totalLoadingMedium,
      totalLoadingHigh,
      totalUnloadingLow,
      totalUnloadingMedium,
      totalUnloadingHigh,
      loadingLowTiming,
      loadingMediumTiming,
      loadingHighTiming,
      unLoadingLowTiming,
      unLoadingMediumTiming,
      unLoadingHighTiming,
      totalDurationCost,
      grandTotalHigh,
      grandTotalMedium,
      grandTotalLow,
      costPerHour,
      bookingfee,
      totalDurationCostBackToBase,
      assembly_disassembly_per_hour_rate

    } = calculateEstimatedTimeAndCost(
      totalSelectedVolume,
      pickupFlights,
      dropOffFlights
    );

    if (totalSelectedVolume > 0) {
      fetch(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/save-inventory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items,
            moversCount,
            totalVehicles,
            totalSelectedVolume,
            totalCost,
            totalLoadingLow,
            totalLoadingMediumn:totalLoadingMedium,
            totalLoadingHigh,
            totalUnloadingLow,
            totalUnloadingMediumn:totalUnloadingMedium,
            totalUnloadingHigh,
            loadingLowTime: loadingLowTiming,
            loadingMediumTime: loadingMediumTiming,
            loadingHighTime: loadingHighTiming,
            unLoadingLowTime: unLoadingLowTiming,
            unLoadingMediumTime: unLoadingMediumTiming,
            unLoadingHighTime: unLoadingHighTiming,
            totalDuration: totalDurationCost,
            grandTotalHigh,
            grandTotalMedium,
            grandTotalLow,
            costPerHour,
            bookingfee,
            totalDurationCostBackToBase,
            assembly_disassembly_per_hour_rate:assembly_disassembly_per_hour_rate,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Response:", data);
          if (data.success) {
            navigate("/movefrom");
          } else {
            alert("An error occurred: please select fields to continue.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to save inventory. Please try again.");
        });
    } else {
      alert("No items selected or invalid volume.");
    }
  };

  useEffect(() => {
    calculateTotalVolume();
    calculateTotalVehicles();
  }, [customItems]);

  const handlenavigate = () => {
    navigate("/movinglocation");
  };

  return (
    <>
      <div className="access-properties">
        <div
          className="nav d-flex justify-content-around"
          role="tablist"
          aria-orientation="vertical"
        >
          <a className="nav-link ">Location</a>
          <a className="nav-link active">Move Size</a>
          <a className="nav-link">Date & Time</a>
          <a className="nav-link">Details</a>
          <a className="nav-link">My Quote</a>
        </div>
        <div className="container">
          <h2 className="text-center p-3">
          Create an inventory to assess the size of your move.
          </h2>
          <p className="text-center p-3 ">
            <a onClick={handlenavigate} className="text-decoration-none">
              <i className="mdi mdi-arrow-left"></i> Change the method for estimation.
            </a>
          </p>
          <div className="row">
            <div className="col-md-4">
              <div className="inventory__romms">
                <div className="">
                  <div id="rooms-container">
                    <h4>Rooms</h4>
                    <div className="room-buttons">
                      {rooms.map((room) => (
                        <button
                          key={room.id}
                          className={`room-button p-4 ${
                            room.id === selectedRoom ? "active" : ""
                          }`}
                          data-room-id={room.id}
                          onClick={() => showRoomDetails(room.id)}
                        >
                          {room.room_name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 pt-4 pt-md-0">
              <div className="inventory__romms">
                <div className="border-0">
                  <div id="rooms-container">
                    {rooms.map((room) => (
                      <div
                        key={room.id}
                        className={`room ${
                          room.id === selectedRoom ? "active" : ""
                        }`}
                      >
                        <h3>{room.room_name}</h3>
                        <hr />
                        <div className="custom-items">
                          {customItems[room.id]?.map((customItem) => (
                            <div
                              key={customItem.id}
                              className="custom-item"
                              data-custom-item-id={customItem.id}
                            >
                              <h5>{customItem.custom_item_name}</h5>
                              <div className="items-list ">
                                {customItem.items.map((item) => (
                                  <div
                                    key={item.id}
                                    className="item assembly_from"
                                    data-item-size={item.item_size}
                                    data-room-id={room.id}
                                    data-item-id={item.id}
                                  >
                                    <div className="row d-flex card-body justify-content-between">
                                      <div className="col-md-6">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <p
                                            className="item-name"
                                            style={{ marginBottom: "0" }}
                                          >
                                            {item.item_name}
                                          </p>
                                          <p
                                            className="item-size text-muted"
                                            style={{ marginTop: "0.25rem" }}
                                          >
                                            {item.item_size}m³
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                                        <button
                                          onClick={() =>
                                            decrementQuantity(
                                              selectedRoom,
                                              item.id
                                            )
                                          }
                                          className="btn room-button btn-sm me-2"
                                        >
                                          -
                                        </button>
                                        <span className="quantity">
                                          {" "}
                                          {item.quantity || 0}
                                        </span>
                                        <button
                                          onClick={() =>
                                            incrementQuantity(
                                              selectedRoom,
                                              item.id
                                            )
                                          }
                                          className="btn room-button btn-sm ms-2"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div id="vehicle-info">
                <h2
                    style={{
                        border: '1px solid #4b49ac',
                        borderRadius: '5px',
                        padding: '10px',
                        textAlign: 'center'
                    }}
                >
                    <i className="mdi mdi-truck text-primary"></i> <span id="vehicle-count">{totalVehicles} Truck</span> + <span id="movers-count">{moversCount}</span> movers
                </h2>
                <div id="truck-names"></div>
                <button className="btn btn-primary" onClick={saveInventory}>Save Inventory</button>
            </div> 
             */}
                </div>
              </div>
            </div>
            {errorMessage && (
              <div className="error-message text-danger">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
      <div className="bg__footerContainer">
        <div className="footer-container">
          <h6
            style={{
              // border: "1px solid #4b49ac",
              borderRadius: "5px",
              padding: "10px",
              textAlign: "center",
              marginBottom: "0px",
              marginRight: "10px",
            }}
            className="total__inventory__truck"
          >
            <i className="mdi mdi-truck text-primary"></i>{" "}
            <span id="vehicle-count">
              <FontAwesomeIcon
                icon={faTruck}
                style={{ marginRight: "10px", color: "#40e550" }}
              />
              {totalVehicles} Truck
            </span>{" "}
            +{" "}
            <span id="movers-count">
              <FontAwesomeIcon
                icon={faUserFriends}
                style={{ marginRight: "10px", color: "#40e550" }}
              />
              {moversCount}
            </span>{" "}
            movers +{" "}
            <span id="movers-count">
              <FontAwesomeIcon
                icon={faCube}
                style={{ marginRight: "10px", color: "#40e550" }}
              />
              {totalVolume}
            </span>{" "}
            m<sup>3</sup>
          </h6>
          <div id="truck-names"></div>
          <button
            type="button"
            className="next_link"
            onClick={saveInventory}
            disabled={buttonDisabled}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
