import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import "../StripePayment/Stripepayment.css"

const StripePaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (!stripe || !elements) {
        return;
      }
  
      const cardElement = elements.getElement(CardElement);
  
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (error) {
        console.error(error);
      } else {
        console.log(paymentMethod);
      }
    };

  return (
    <form onSubmit={handleSubmit}>
    <div className="form-group-custom">
      <label htmlFor="card-element">Card number</label>
      <CardElement id="card-element" className="form-control-custom" />
    </div>
    <div className="form-group-custom">
      <label htmlFor="country">Country</label>
      <select id="country" className="form-control-custom">
        <option>India</option>
        <option>USA</option>
        <option>Canada</option>
        <option>Australia</option>
        {/* Add other countries as needed */}
      </select>
    </div>
    <p className='pt-2'>By providing your card information, you allow Instamove Pty Ltd to charge your card for future payments in accordance with their terms.</p>
  </form>
  );
};

export default StripePaymentForm;
