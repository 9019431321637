import React, { useState } from "react";
import "../Details/Details.css";
import deal from "../../Assests/deal.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

export default function Details() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [userExists, setUserExists] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showNextButton, setShowNextButton] = useState(false);
  const [showSendMailLink, setShowSendMailLink] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.mobile) {
      newErrors.mobile = "Contact phone is required.";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number.";
    }
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Fetch user details when email input is filled
    if (name === "email" && value) {
      fetchUserDetails(value);
    }
  };

  const fetchUserDetails = async (email) => {
    try {
      const response = await axios.get(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/user--data"
      );
      const users = response.data;
  
      const matchingUser = users.find(
        (user) => user.email.toLowerCase() === email.toLowerCase()
      );
      const sessionToken = localStorage.getItem("session_token");
  
      if (matchingUser) {
        setUserExists(true);
        setUserDetails(matchingUser);
  
        if (sessionToken) {
          setShowNextButton(true);
          setShowSendMailLink(false);
        } else {
          setShowNextButton(false);
          setShowSendMailLink(true);
        }
      } else {
        setUserExists(false);
        setUserDetails(null);
        setShowNextButton(true);
        setShowSendMailLink(false);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  };
  

  const handleSendQuoteLink = async () => {
    try {
      const enquiry_id = localStorage.getItem("enquiry_id");
  
      if (!enquiry_id) {
        setModalContent("Enquiry ID is missing.");
        setShowModal(true);
        return;
      }
  
      const response = await axios.post(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/send-quote-link",
        {
          email: formData.email,
          enquiry_id: enquiry_id,
        }
      );
  
      console.log(response.data); // Log the response data
      const { session_token, name, customer_id } = response.data;
  
      localStorage.setItem("session_token", session_token);
      localStorage.setItem("enquiry_id", enquiry_id);
      localStorage.setItem("name", name);
      localStorage.setItem("customer_id", customer_id);
  
      // Show modal with success message
      setModalContent("Link to view quote sent to your email.");
      setShowModal(true);
  
      // Update state to show the "Next" button and hide "Send me link for view quote" button
      setShowSendMailLink(false);
      setShowNextButton(true);
    } catch (error) {
      console.error("Error sending quote link", error);
      setModalContent(
        "An error occurred while sending the quote link. Please try again later."
      );
      setShowModal(true);
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true); // Show loader

    try {
      const enquiry_id = localStorage.getItem("enquiry_id");
      if (!enquiry_id) {
        setModalContent("Enquiry ID is missing.");
        setShowModal(true);
        setLoading(false);
        return;
      }

      const response = await fetch(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/post-customer-details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            enquiry_id: enquiry_id,
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
          }),
        }
      );

      const result = await response.json(); // Parse response as JSON

      if (response.ok) {
        const { session_token, name, estimate_type, id } = result;

        // Save session token and enquiry_id to localStorage
        localStorage.setItem("session_token", session_token);
        localStorage.setItem("enquiry_id", enquiry_id);
        localStorage.setItem("token", session_token);
        localStorage.setItem("name", name);
        localStorage.setItem("customer_id", id);

        // Convert estimate_type to integer
        const estimateType = parseInt(estimate_type, 10);

        // Navigate based on estimate_type
        switch (estimateType) {
          case 1:
            navigate("/inventorymyquote");
            window.location.reload();
            break;
          case 2:
            navigate("/quickmyquote");
            window.location.reload();
            break;
          case 3:
            navigate("/handlfullmyquote");
            window.location.reload();
            break;
          default:
            setModalContent("Unknown estimate type returned from the server.");
            setShowModal(true);
            break;
        }
      } else {
        setModalContent(
          result.message || "An error occurred while submitting the form."
        );
        setShowModal(true);
      }
    } catch (error) {
      setModalContent("Network error, please try again later.");
      setShowModal(true);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <>
      <div className="access-properties">
        <div className="container">
          <div
            className="nav d-flex justify-content-around"
            role="tablist"
            aria-orientation="vertical"
          >
            <a className="nav-link">Location</a>
            <a className="nav-link">Move Size</a>
            <a className="nav-link">Date & Time</a>
            <a className="nav-link active">Details</a>
            <a className="nav-link">My Quote</a>
          </div>
          <div className="mobile-navigation">
            <span className="tab-label">Details</span>
          </div>
          <h3 className="text-center">
            Where would you like us to send your instant quote?
          </h3>
          <p className="text-center">
            We'll send your quote via email and SMS.
          </p>
          <div className="quote-container" style={{ paddingBottom: "2%" }}>
            <form className="quote-form" onSubmit={handleSubmit}>
              <div className="quote-form-group">
                <label className="quote-form-label" htmlFor="name">
                  <i className="fas fa-user details__logo"></i> Name:
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="quote-form-input w-100"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>

              <div className="quote-form-group">
                <label className="quote-form-label" htmlFor="email">
                  <i className="fas fa-envelope details__logo"></i> Email:
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="quote-form-input w-100"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && <p className="text-danger">{errors.email}</p>}
              </div>

              <div className="quote-form-group">
                <label className="quote-form-label" htmlFor="phone">
                  <i className="fas fa-phone details__logo"></i> Contact Phone:
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="quote-form-input w-100"
                  name="mobile"
                  type="tel"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
                {errors.mobile && (
                  <p className="text-danger">{errors.mobile}</p>
                )}
              </div>

              {loading && (
                <div className="loader-container">
                  <FallingLines
                    color="#FFB629"
                    width="100"
                    visible={true}
                    ariaLabel="falling-lines-loading"
                  />
                </div>
              )}

{showSendMailLink && (
  <div className="text-center mt-3">
    <button
      className="next_link"
      type="button"
      onClick={handleSendQuoteLink}
    >
      Send me link for view quote
    </button>
  </div>
)}

{showNextButton && (
  <div className="text-center mt-3">
    <button
      className="next_link"
      type="submit"
    >
      Next
    </button>
  </div>
)}

            </form>
          </div>
        </div>
      </div>

      {/* Modal for showing messages */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}