import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Date&Time/Date&Time.css";
import uploadIcon from "../../Assests/upload.png";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const initialItems = {
  bed: { name: "Bed", approx: "5 minutes", count: 0 },
  desk: { name: "Desk", approx: "5 minutes", count: 0 },
  diningTable: {
    name: "Dining Table (Large only)",
    approx: "5 minutes",
    count: 0,
  },
  babyCot: { name: "Baby Cot", approx: "5 minutes", count: 0 },
  bunkBed: { name: "Bunk Bed", approx: "5 - 10 minutes", count: 0 },
  trampoline: { name: "Trampoline", approx: "10 - 15 minutes", count: 0 },
};

const disassemblyItems = {
  bed: { name: "Bed", approx: "5 minutes", count: 0 },
  desk: { name: "Desk", approx: "5 minutes", count: 0 },
  diningTable: {
    name: "Dining Table (Large only)",
    approx: "5 minutes",
    count: 0,
  },
  babyCot: { name: "Baby Cot", approx: "5 minutes", count: 0 },
  bunkBed: { name: "Bunk Bed", approx: "5 - 10 minutes", count: 0 },
  trampoline: { name: "Trampoline", approx: "10 - 15 minutes", count: 0 },
};

const reassemblyItems = {
  bed: { name: "Bed", approx: "5 - 10 minutes", count: 0 },
  desk: { name: "Desk", approx: "5 minutes", count: 0 },
  diningTable: {
    name: "Dining Table (Large only)",
    approx: "5 minutes",
    count: 0,
  },
  babyCot: { name: "Baby Cot", approx: "5 minutes", count: 0 },
  bunkBed: { name: "Bunk Bed", approx: "10 - 15 minutes", count: 0 },
  trampoline: { name: "Trampoline", approx: "15 - 20 minutes", count: 0 },
};

const bothItems = {
  bed: { name: "Bed", approx: "10 - 15 minutes", count: 0 },
  desk: { name: "Desk", approx: "5 - 10 minutes", count: 0 },
  diningTable: {
    name: "Dining Table (Large only)",
    approx: "5 - 10 minutes",
    count: 0,
  },
  babyCot: { name: "Baby Cot", approx: "5 - 10 minutes", count: 0 },
  bunkBed: { name: "Bunk Bed", approx: "15 - 25 minutes", count: 0 },
  trampoline: { name: "Trampoline", approx: "20 - 35 minutes", count: 0 },
};

const MoveForm = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [startDate, setStartDate] = useState(new Date());
  const [assembly, setAssembly] = useState("");
  const [items, setItems] = useState(initialItems);
  const [showAssemblyForm, setShowAssemblyForm] = useState(false);
  const [assemblyOption, setAssemblyOption] = useState("");
  const [noteText, setNoteText] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleAssemblySelection = (value) => {
    setAssembly(value);
    setShowAssemblyForm(value === "yes");
  };

  const handleItemChange = (item, change) => {
    setItems((prevItems) => ({
      ...prevItems,
      [item]: {
        ...prevItems[item],
        count: Math.max(prevItems[item].count + change, 0),
      },
    }));
  };

  const handleAssemblyOptionChange = (e) => {
    const value = e.target.value;
    setAssemblyOption(value);

    if (value === "disassembly") {
      setItems(disassemblyItems);
    } else if (value === "reassembly") {
      setItems(reassemblyItems);
    } else if (value === "both") {
      setItems(bothItems);
    }
  };

  const calculateTotalTime = (items) => {
    let totalLowTime = 0;
    let totalHighTime = 0;
  
    Object.values(items).forEach((item) => {
      if (item.count > 0) {
        // Split the approx time and handle cases with single time values
        const times = item.approx.split(" - ").map((time) => parseInt(time));
        const low = times[0];
        const high = times[1] || low; // If no high value, use low value
  
        totalLowTime += low * item.count;
        totalHighTime += high * item.count;
      }
    });
  
    return { totalLowTime, totalHighTime };
  };


  const logFormData = async () => {
    const Enquiry_id = localStorage.getItem('enquiry_id');
    const url = 'https://demo.webwideit.solutions/cbd-instamove/public/api/post-assembly-disembelly';
  
    // Define a mapping for item names to item_ids
    const itemIdMapping = {
      "bed": 1,
      "desk": 2,
      "dining table (large only)": 3,
      "baby cot": 4,
      "bunk bed": 5,
      "trampoline": 6
    };
  
    // Prepare data for POST request
    const postData = {
      enquiry_id: Enquiry_id,
      items: Object.keys(items).map((key) => {
        const item = items[key];
        const item_id = itemIdMapping[item.name.toLowerCase()] || 'Unknown';
        return {
          item_id: item_id,
          name: item.name,
          quantity: item.count
        };
      })
    };
  
    // Log the data to be posted
    console.log("Posting Data:");
    console.log(postData);
  
    try {
      // Send POST request
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log("Response:", result);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  
  // Call the function to post the data
  
  
  
  


  
  const handlenextdateSubmit = async (e) => {
    e.preventDefault();
    logFormData()
    // Debugging statements
    console.log("startDate:", startDate);
    console.log("arrivalTime:", arrivalTime);
    console.log("assemblyOption:", assembly);
  
    // Validate required fields
    if (!startDate || !arrivalTime || !assembly) {
      setError("All the fields are required.");
      return;
    }
  
    if (assembly === "yes" && !Object.values(items).some((item) => item.count > 0)) {
      setError("Please select at least one item for assembly.");
      return;
    }
  
    // Calculate totalLowTime and totalHighTime using the function
    const { totalLowTime, totalHighTime } = calculateTotalTime(items);
  
    console.log("totalLowTime:", totalLowTime, "minutes");
    console.log("totalHighTime:", totalHighTime, "minutes");
  
    const enquiryId = localStorage.getItem("enquiry_id");
    const data = {
      enquiry_id: enquiryId,
      selected_date: startDate.toISOString().split("T")[0],
      arrive_on: arrivalTime,
      assembly_or_disassembly: assemblyOption,
      note_text: noteText || "",
      total_assemble_dissamble_low_time: totalLowTime,
      total_assemble_dissamble_high_time: totalHighTime,
    };
  
    console.log("Data to be sent:", data);
  
    try {
      // Call handleUpload before making the API request
      await handleUpload();
  
      const response = await fetch(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/save-date",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );
  
      console.log("Response Status:", response.status);
      console.log("Response Headers:", response.headers);
  
      if (!response.ok) {
        const errorData = await response.text();
        console.error("Error:", errorData);
        setError(
          `HTTP error! Status: ${response.status}. Details: ${errorData}`
        );
        return;
      }
  
      const result = await response.json();
      console.log("Success:", result);
  
      navigate("/details");
    } catch (error) {
      console.error("Error:", error);
      setError("There was an error submitting the form. Please try again.");
    }
  };
  
  // const handlenextdateSubmit =() =>{
  //   navigate("/details")
  // }

  const handleArrivalTimeChange = (e) => {
    setArrivalTime(e.target.value);
  };
  // const [file, setFile] = useState(null);

  // const [file, setFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Create a local URL for the selected image
      const imageUrl = URL.createObjectURL(selectedFile);
      setFile(selectedFile);
      setUploadedImage(imageUrl);
    }
  };

  const handleUpload = async () => {
    // if (!file) {
    //   alert("Please select a file first.");
    //   return;
    // }

    // Get enquiry_id from local storage
    const enquiry_id = localStorage.getItem("enquiry_id");

    if (!enquiry_id) {
      alert("Enquiry ID not found in local storage.");
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("image", file);
    formData.append("enquiry_id", enquiry_id);

    try {
      const response = await fetch(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/upload-image",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (response.ok) {
        // alert("File uploaded successfully!");
        // The image URL can be constructed or processed if needed
        console.log("Image uploaded successfully.", result);
      } else {
        // alert("File upload failed: " + result.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // alert("Error uploading file.");
    }
  };

  useEffect(() => {
    // Enable button only if all required fields are filled
    if (startDate && arrivalTime && assembly ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [startDate, arrivalTime, assembly, noteText, uploadedImage]);
  return (
    <>
      <div className="access-properties properties__date">
        <div className="container ">
          <div
            className="nav d-flex justify-content-around"
            role="tablist"
            aria-orientation="vertical"
          >
            <a className="nav-link ">Location</a>
            <a className="nav-link">Move Size</a>
            <a className="nav-link active">Date & Time</a>
            <a className="nav-link">Details</a>
            <a className="nav-link">My Quote</a>
          </div>
          <div className="mobile-navigation pt-3 pt-md-0">
            {/* <button onClick={prevTab} disabled={activeTab === "tab1"}>
                &lt;
              </button> */}
            <span className="tab-label">Date & Time</span>
            {/* <button onClick={nextTab} disabled={activeTab === "tab5"}>
                &gt;
              </button> */}
          </div>
          <h2 className="text-center ">
            When would you like to schedule the move?
          </h2>
          <p className="text-center">
            Not sure? Make your best guess and reschedule later— we're flexible!
          </p>
          <div className="move-form">
            <h4>Move Date</h4>
            <div className="calendar">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                inline
                monthsShown={1}
                calendarClassName="custom-calendar"
              />
            </div>
            <div className="arrival-time">
              <h4>When should we arrive?</h4>
              <div className="radio-group schedule__group">
                <label>
                  <input
                    type="radio"
                    name="arrivalTime"
                    value="Morning 7:00 - 12:00am"
                    onChange={handleArrivalTimeChange}
                  />
                  Morning 7:00 - 12:00am
                </label>
                <label>
                  <input
                    type="radio"
                    name="arrivalTime"
                    value="Afternoon 12:00 - 3:00pm"
                    onChange={handleArrivalTimeChange}
                  />
                  Afternoon 12:00 - 3:00pm
                </label>
                {/* <label>
                  <input
                    type="radio"
                    name="arrivalTime"
                    value="custom"
                    onChange={handleArrivalTimeChange}
                  />
                  Custom
                </label> */}
              </div>
            </div>
            <div className="assembly">
              <h4>Need furniture assembly or disassembly?</h4>
              <div className="radio-group assembly_options">
                <label>
                  <input
                    type="radio"
                    name="assembly"
                    value="yes"
                    checked={assembly === "yes"}
                    onChange={() => handleAssemblySelection("yes")}
                  />
                  Yes please
                </label>
                <label>
                  <input
                    type="radio"
                    name="assembly"
                    value="no"
                    checked={assembly === "no"}
                    onChange={() => handleAssemblySelection("no")}
                  />
                  No thanks
                </label>
              </div>
            </div>
            {showAssemblyForm && (
              <>
                <div className="select-items assembly_bg mt-2 date__timebg">
                  <h5>Select your items</h5>
                  <hr />
                  <Form.Group controlId="assemblyOption" className="my-3">
                    <Form.Label>
                      Would you like both Disassembly & Reassembly?
                    </Form.Label>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Check
                        type="radio"
                        label="Yes, both"
                        value="both"
                        checked={assemblyOption === "both"}
                        onChange={handleAssemblyOptionChange}
                        className="custom-radio"
                      />
                      <Form.Check
                        type="radio"
                        label="Disassembly only"
                        value="disassembly"
                        checked={assemblyOption === "disassembly"}
                        onChange={handleAssemblyOptionChange}
                        className="custom-radio"
                      />
                      <Form.Check
                        type="radio"
                        label="Reassembly only"
                        value="reassembly"
                        checked={assemblyOption === "reassembly"}
                        onChange={handleAssemblyOptionChange}
                        className="custom-radio"
                      />
                    </div>
                    <span className="text-danger">
                      * Please select one of the above
                    </span>
                  </Form.Group>
                  <hr />
                  {Object.keys(items).map((key) => (
                    <div
                      key={key}
                      className="item-row d-flex justify-content-between assembly_from"
                    >
                      <div>
                        <span>{items[key].name}</span>
                        <div>
                          <span style={{ color: "#98a2b3" }}>
                            Approx {items[key].approx}
                          </span>
                        </div>
                      </div>

                      <div className="item-controls ">
                        <Button
                          variant=" room-button"
                          onClick={() => handleItemChange(key, -1)}
                        >
                          -
                        </Button>
                        <span className="p-2">{items[key].count}</span>
                        <Button
                          variant=" room-button"
                          onClick={() => handleItemChange(key, 1)}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="additional-info">
              <h4>Anything else we should know?</h4>
              <p>
                Is truck access difficult? Do you have any fragile or very heavy
                items (over 150kg)?
              </p>
              <textarea
                placeholder="Type here"
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
              ></textarea>
            </div>
            <div className="file-upload" style={{ marginTop: "20px" }}>
              <h3>Attach a photo if needed</h3>
              <div
                className="upload-box"
                // style={{
                //   display: "flex",
                //   flexDirection: "column",
                //   alignItems: "center",
                //   justifyContent: "center",
                // }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={uploadIcon}
                    alt="Upload"
                    style={{
                      width: "40px", // Adjust size as needed
                      height: "40px", // Adjust size as needed
                      marginRight: "10px",
                    }}
                  />
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{
                      display: "none", // Hide the default file input
                    }}
                    accept="image/*" // Restrict to image files if needed
                  />
                </label>
                <span style={{ color: "#8e4fe9" }}>click to choose image</span>
                {uploadedImage && (
                  <div style={{ marginTop: "20px" }}>
                    <img
                      src={uploadedImage}
                      alt="Selected"
                      style={{
                        maxWidth: "120px",
                        maxHeight: "120px",
                        objectFit: "cover",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {error && <div className="error-message text-danger">{error}</div>}
          </div>
        </div>
      </div>
      <div className="bg__footerContainer">
        <div className="footer-container">
          <button
            type="button"
            className={`next_link ${buttonDisabled ? "disabled" : ""}`}
            onClick={handlenextdateSubmit}
            disabled={buttonDisabled}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
};

export default MoveForm;
