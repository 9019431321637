import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "../Footer/Footer.css";
import logo from "../Assests/cargo_truck.png";
import { Link } from 'react-router-dom';
import logos from "../Assests/Skilled-Movers-Logo.png"


const CustomFooter = () => {
  return (
    <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="footer-logo">
            <img src={logos} alt="Logo" width="30%"  />
            <p className="description">
            Welcome to CBD Way Mover, your trusted partner for relocating your home.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="footer-links">
            <h3 className="text-dark">Useful Links</h3>
            <div className="divider"></div>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/faqs">FAQ'S</Link>
              </li>
              <li>
                <Link to="/contactus">Contact</Link>
              </li>
            </ul>
          </div>
        </div>  
        <div className="col-md-4">
          <div className="footer-contact">
            <h3 className="text-dark">Contact Details</h3>
            <div className="divider"></div>
            <div className="contact-info">
              <p style={{ color: "#7e7e7e" }}>
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <a
                  href="#"
                  className="text-decoration-none conatctmail"
                  style={{ color: "#7e7e7e" }}
                >
                  Info@Advanceshipping.Com
                </a>
              </p>
              <p style={{ color: "#7e7e7e" }}>
                <FontAwesomeIcon icon={faPhone} className="me-2" />
                <a
                  href="#"
                  className="text-decoration-none conatctmail"
                  style={{ color: "#7e7e7e" }}
                >
                  +1 607 722 2030
                </a>
              </p>
              <div className="social-icons">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <p className="copyright text-dark">Copyright © 2024, All Right Reserved</p>
        </div>
      </div>
    </div>
  </footer>
  
  );
};

export default CustomFooter;
