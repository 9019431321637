import React, { useEffect, useState } from "react";
import axios from "axios";

const QuoteDetail = ({ enquiryId }) => {
  const [data, setData] = useState(null);
  const [enquirys, setEnquirys] = useState(null);
  const [roomsData, setRoomsData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (enquiryId) {
          const response = await axios.get(
            `https://demo.webwideit.solutions/cbd-instamove/public/api/sheduled-view-detailed-estimate-booking/${enquiryId}`
          );
          setData(response.data.enquirydetails);
          setEnquirys(response.data.enquirys);

          const processedRoomsData = Object.keys(response.data.enquirys).map(
            (roomId) => {
              const roomItems = response.data.enquirys[roomId];
              return {
                roomName: roomItems[0]?.roomName || "Unknown Room",
                items: roomItems.reduce((acc, item) => {
                  const customItem = acc.find(
                    (ci) => ci.customItemName === item.customItemName
                  );
                  if (customItem) {
                    customItem.items.push({
                      itemId: item.itemId,
                      itemName: item.itemName,
                      quantity: item.quantity,
                    });
                  } else {
                    acc.push({
                      customItemName:
                        item.customItemName || "Unknown Custom Item",
                      items: [
                        {
                          itemId: item.itemId,
                          itemName: item.itemName,
                          quantity: item.quantity,
                        },
                      ],
                    });
                  }
                  return acc;
                }, []),
              };
            }
          );

          setRoomsData(processedRoomsData);
        } else {
          setError("No quote ID found in local storage");
        }
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-3">
      <h2 style={{ color: "#8e4fe9" }} className="fs-3">
        Inventory List
      </h2>
      <hr />
      {roomsData.map((room) => (
        <div key={room.roomName}>
          <h3 style={{ color: "#292767" }} className="fs-4">
            {room.roomName}
          </h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {room.items.map((customItem) => (
                customItem.items.map((item) => (
                  <tr key={item.itemId}>
                    <td>{item.itemName}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default QuoteDetail;