import React from "react";
import "../ContactUs/Contactus.css";

export default function ContactUs() {
  return (
    <>
      <section className="contact-container">
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-6  contact-info-container">
              <h1 className="">Give us a call to chat!</h1>
              <p className="text-justify">
                We move thousands of people annually. Have questions? Call us
                anytime — our friendly team is available 7 days a week to assist
                you.{" "}
              </p>
              <div className="contact-info-item">
                <div className="contact-icon">
                  <i className="fas fa-phone"></i>
                </div>
                <div>
                  <p>Call us on</p>
                  <a href="tel:607 722 2030">+1 607 722 2030</a>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-icon">
                  <i className="fas fa-envelope"></i>
                </div>
                <div>
                  <p>Email us at</p>
                  <a href="mailto:Info@Advanceshipping.com">
                    Info@Advanceshipping.com
                  </a>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-icon">
                  <i className="fas fa-map-marker-alt" style={{color:"#40e550"}}></i>
                </div>
                <div>
                  <p>Mail us at</p>
                  <p>Pune.webwideit.solutions.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6 ">
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345094287!2d144.95565131562035!3d-37.81732774223595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57766f0f3a9a4a8!2sPO%20Box%201081%2C%20Mitcham%20North%20VIC%203132%2C%20Australia!5e0!3m2!1sen!2sus!4v1622537045025!5m2!1sen!2sus"
                  width="100%"
                  height="500"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
