import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Completed = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://demo.webwideit.solutions/cbd-instamove/public/api/sheduled-detailed-estimate-bookings/212'
        );
        const result = await response.json();
        
        if (response.ok && result.success) {
          // Assuming the data you want to display is under `enquirys` key in the API response
          const bookings = Object.values(result.enquirys).flat();
          setData(bookings);
        } else {
          console.error('Failed to fetch data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container id="scrollable-complete">
      <Row>
        {data.map((item, index) => (
          <Col key={index} className="mb-4" xs={12}>
            <div className="shadow p-3 bg-white rounded">
              <h5>Booking {index + 1}</h5>
              <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                <div className="font-weight-bold">Pickup Location:</div>
                <div className="mt-1 mt-sm-0">{item.fromlocation}</div>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                <div className="font-weight-bold">Dropoff Location:</div>
                <div className="mt-1 mt-sm-0">{item.tolocation}</div>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                <div className="font-weight-bold">Total Cost:</div>
                <div className="mt-1 mt-sm-0">${item.totalCost}</div>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                <div className="font-weight-bold">Status:</div>
                <div
                  className={`mt-1 mt-sm-0 ${
                    item.status === "1" ? "status-paid" : "status-unpaid"
                  }`}
                >
                  {item.status === "1" ? "Paid" : "Unpaid"}
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Completed;
