import React, { useState, useEffect } from "react";
import "../SizeEstimate/Size.css";
import { useNavigate } from "react-router-dom";
import {
  faCube,
  faTruck,
  faUser,
  faDollarSign,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoveForm from "../Date&Time/Date&Time"; // Ensure this import is used correctly if needed

function Size() {
  const [livingAreas, setLivingAreas] = useState("");
  const [furnitureVolume, setFurnitureVolume] = useState("");
  const [potPlants, setPotPlants] = useState("");
  const [outsideMove, setOutsideMove] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [rateData, setRateData] = useState([]);
  const [cubicSize, setCubicSize] = useState(0);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [moversCount, setMoversCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [timeRequired, setTimeRequired] = useState(0);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [pickupFlights, setPickupFlight] = useState("");
  const [dropOffFlights, setDropOffFlight] = useState("");
  const [setDuration, setTotalDuration] = useState("");
  const [setBaseTime, setBaseAddressTime] = useState("");
  const [totalLoadingLow, setTotalLoadingLow] = useState(null);
  const [totalLoadingMedium, setTotalLoadingMedium] = useState(null);
  const [totalLoadingHigh, setTotalLoadingHigh] = useState(null);
  const [totalUnloadingLow, setTotalUnloadingLow] = useState(null);
  const [totalUnloadingMedium, setTotalUnloadingMedium] = useState(null);
  const [totalUnloadingHigh, setTotalUnloadingHigh] = useState(null);

  const [loadingLowTiming, setLoadingLowTiming] = useState(null);
  const [loadingMediumTiming, setLoadingMediumTiming] = useState(null);
  const [loadingHighTiming, setLoadingHighTiming] = useState(null);
  const [unLoadingLowTiming, setUnLoadingLowTiming] = useState(null);
  const [unLoadingMediumTiming, setUnLoadingMediumTiming] = useState(null);
  const [unLoadingHighTiming, setUnLoadingHighTiming] = useState(null);

  const [totalDurationCost, setTotalDurationCost] = useState(null);
  const [grandTotalHigh, setGrandTotalHigh] = useState(null);
  const [grandTotalMedium, setGrandTotalMedium] = useState(null);
  const [grandTotalLow, setGrandTotalLow] = useState(null);
  const [costPerHour, setCostPerHour] = useState(null);
  const [bookingFee, setBookingFee] = useState(null);
  const [grandTotalBackToBase, setGrandTotalBackToBase] = useState(null);
  const [requiredVehicles, setTotalVehicles] = useState(null);
  const [assembly_disassembly_per_hour_rate, setAssemblyDisassemblyRate] = useState(0);



  const navigate = useNavigate();

  const handleGoBack = (e) => {
    e.preventDefault();
    navigate("/movinglocation");
  };
  useEffect(() => {
    calculateTotalVehicles();
  }, [cubicSize]);

  useEffect(() => {
    // Enable button only if all required fields are filled
    if (bedrooms && livingAreas && livingAreas && outsideMove && potPlants) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [bedrooms, livingAreas, livingAreas, outsideMove, potPlants]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!bedrooms) errors.bedrooms = "Number of bedrooms is required.";
    if (!livingAreas)
      errors.livingAreas = "Number of living areas is required.";
    if (!furnitureVolume)
      errors.furnitureVolume = "Volume of furniture is required.";
    if (!outsideMove)
      errors.outsideMove = "Outside move description is required.";
    if (!potPlants) errors.potPlants = "Number of pot plants is required.";

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setError("Please fill in all required fields.");
      return;
    }

    setValidationErrors({});
    setError("");

    const enquiry_id = localStorage.getItem("enquiry_id");
    const data = {
      how_many_bedroom: bedrooms,
      living_areas_with_couches: livingAreas,
      volume_of_furniture: furnitureVolume,
      move_from_outside_your_home: outsideMove,
      number_of_pot_plants: potPlants,
      enquiry_id,
      total_time: timeRequired,
      total_cost: totalCost,
      totalLoadingLow: totalLoadingLow,
      totalLoadingMedium: totalLoadingMedium,
      totalLoadingHigh: totalLoadingHigh,
      totalUnloadingLow: totalUnloadingLow,
      totalUnloadingMedium: totalUnloadingMedium,
      totalUnloadingHigh: totalUnloadingHigh,
      loadingLowTiming: loadingLowTiming,
      loadingMediumTiming: loadingMediumTiming,
      loadingHighTiming: loadingHighTiming,
      unLoadingLowTiming: unLoadingLowTiming,
      unLoadingMediumTiming: unLoadingMediumTiming,
      unLoadingHighTiming: unLoadingHighTiming,
      totalDurationCost: totalDurationCost,
      grandTotalHigh: grandTotalHigh,
      grandTotalMedium: grandTotalMedium,
      grandTotalLow: grandTotalLow,
      costPerHour: costPerHour,
      bookingfee: bookingFee,
      totalDurationCostBackToBase: grandTotalBackToBase,
      requiredVehicles: vehicleCount,
      mover:moversCount,
      cubic_size:cubicSize,
      assembly_disassembly_per_hour_rate
    };

    console.log("Form Data:", data);

    try {
      const response = await fetch(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/post-quick-inventory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorData = await response.text();
        console.error("Error:", response.status, errorData);
        setError(`HTTP error! Status: ${response.status}`);
        return;
      }

      const result = await response.json();
      console.log("Success:", result);
      navigate("/movefrom");
    } catch (error) {
      console.error("Error:", error);
      setError("There was an error submitting the form. Please try again.");
    }
  };

  const handlebackNavigate = () => {
    navigate("/movinglocation");
  };

  useEffect(() => {
    fetchEnquiry();
  });

  const fetchEnquiry = () => {
    const enquiry_id = localStorage.getItem("enquiry_id");

    fetch(
      `https://demo.webwideit.solutions/cbd-instamove/public/api/enquiry-data/${enquiry_id}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is an array with one object
        if (data.length > 0) {
          const enquiryData = data[0]; // Get the first object

          // Extract the relevant fields
          const pickupFlights = enquiryData.how_many_flights_of_stairs;
          const dropOffFlights = enquiryData.drop_of_how_many_flights_of_stairs;
          const totalDuration = enquiryData.total_duration;
          const baseAddressTime = enquiryData.baseAddress_time;

          // Update the state with the extracted values
          setPickupFlight(pickupFlights);
          setDropOffFlight(dropOffFlights);
          setTotalDuration(totalDuration);
          setBaseAddressTime(baseAddressTime);

          // Log the extracted values for debugging
          console.log(".........pickupFlights", pickupFlights);
          console.log("............dropOffFlights", dropOffFlights);
          console.log("............totalDuration", totalDuration);
          console.log("............baseAddressTime", baseAddressTime);
        } else {
          console.log("No enquiry data found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching enquiry data:", error);
      });
  };

  const calculateTotalVehicles = () => {
    const { requiredVehicles } = getRequiredVehicles(cubicSize);
    setTotalVehicles(requiredVehicles);
  };
 
  const getRequiredVehicles = (cubicSize) => {
    let remainingVolume = cubicSize;
    let truckNames = [];
    let totalVehicles = 0;

    for (const vehicle of vehicles) {
      if (remainingVolume <= 0) break;

      const truckSize = vehicle.size;
      if (remainingVolume <= truckSize) {
        truckNames.push(`${vehicle.name} (x1)`);
        totalVehicles += 1;
        remainingVolume = 0;
        break;
      }
    }

    if (remainingVolume > 0) {
      for (const vehicle of vehicles.reverse()) {
        if (remainingVolume <= 0) break;

        const truckSize = vehicle.size;
        const count = Math.ceil(remainingVolume / truckSize);
        remainingVolume -= count * truckSize;
        totalVehicles += count;
        truckNames.push(`${vehicle.name} (x${count})`);
      }
    }

    return { requiredVehicles: totalVehicles, truckNames };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vehicleResponse = await fetch(
          "https://demo.webwideit.solutions/cbd-instamove/public/api/vehicle-size"
        );
        const vehicleData = await vehicleResponse.json();
        setVehicles(vehicleData);
  
        const rateResponse = await fetch(
          "https://demo.webwideit.solutions/cbd-instamove/public/api/rate-data"
        );
        const rateData = await rateResponse.json();
        setRateData(
          rateData.map((rate) => ({
            cubic_meter_size_start: parseFloat(rate.cubic_meter_size_start),
            cubic_meter_size_end: parseFloat(rate.cubic_meter_size_end),
            time_required: parseFloat(rate.time_required),
            movers_needed: parseInt(rate.movers_needed),
            per_hour_rate: parseFloat(rate.per_hour_rate),
            loading_high_time: rate.loading_high_time,
            loading_medium_time: rate.loading_medium_time,
            loading_low_time: rate.loading_low_time,
            unloading_high_time: rate.unloading_high_time,
            unloading_medium_time: rate.unloading_medium_time,
            unloading_low_time: rate.unloading_low_time,
            booking_fee: rate.booking_fee,
            loading_oneflight: rate.loading_oneflight,
            loading_secondflight: rate.loading_secondflight,
            loading_thirdflight: rate.loading_thirdflight,
            unloading_oneflight: rate.unloading_oneflight,
            unloading_secondflight: rate.unloading_secondflight,
            unloading_thirdflight: rate.unloading_thirdflight,
            assembly_disassembly_per_hour_rate: parseFloat(rate.assembly_disassembly_per_hour_rate)
          }))
        );
  
        // Assuming you want to set the rate of the first item as default
        if (rateData.length > 0) {
          setAssemblyDisassemblyRate(parseFloat(rateData[0].assembly_disassembly_per_hour_rate));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {

    const fetchScenarios = async () => {
      try {
        const response = await fetch('https://demo.webwideit.solutions/cbd-instamove/public/api/scenarios');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const scenarios = await response.json();
    
        // Verify the structure of the data
        console.log('Fetched scenarios:', scenarios);
    
        // Example: Check the first scenario
        if (scenarios.length > 0) {
          console.log('First scenario:', scenarios[0]);
        }
    
        return scenarios;
      } catch (error) {
        console.error('Error fetching scenarios:', error);
        return [];
      }
    };
    const calculateEstimate = async () => {
      let newCubicSize = 0;
    
      try {
        // Fetch scenarios
        const scenarios = await fetchScenarios();
        console.log("Scenarios:", scenarios);
    
        // Get user selections with default values
        const bedroomSize = bedrooms || "0";
        const livingAreasValue = livingAreas ? livingAreas.toString() : "0";
        const furnitureType = furnitureVolume || "Lightly furnished";
        const moveOutside = outsideMove || "Nothing";
        const potPlantsValue = potPlants || "None";
    
        console.log("Bedroom Size:", bedroomSize);
        console.log("Living Areas Value:", livingAreasValue);
        console.log("Furniture Type:", furnitureType);
        console.log("Move Outside:", moveOutside);
        console.log("Pot Plants Value:", potPlantsValue);
    
        // Find matching scenario
        const matchingScenario = scenarios.find(
          (scenario) =>
            scenario.bedroom_size === bedroomSize &&
            scenario.living_area_with_couches.toString() === livingAreasValue &&
            scenario.furniture_type === furnitureType &&
            scenario.move_from_outside === moveOutside &&
            scenario.number_of_pot_plants === potPlantsValue
        );
    
        if (matchingScenario) {
          newCubicSize = parseFloat(matchingScenario.cubic_size);
          console.log("Total Cubic Size from scenario:", newCubicSize);
        } else {
          console.log("No matching scenario found, using default cubic size");
          newCubicSize = 0; 
        }
    
        setCubicSize(newCubicSize);
        console.log("Cubic Size Set:", newCubicSize);
    
        // Determine vehicle count
        let newVehicleCount = 0;
        let vehicleSize = "Unknown";
        for (let vehicle of vehicles) {
          if (vehicle.vehicle_size >= newCubicSize) {
            vehicleSize = vehicle.vehicle_type;
            newVehicleCount = 1;
            break;
          }
        }
    
        if (vehicleSize === "Unknown") {
          for (let vehicle of vehicles) {
            if (vehicle.vehicle_size < newCubicSize) {
              vehicleSize = vehicle.vehicle_type;
              newVehicleCount = Math.ceil(newCubicSize / vehicle.vehicle_size);
              break;
            }
          }
        }
    
        setVehicleCount(newVehicleCount);
    
        // Determine movers count and total cost
        const rate = rateData.find(
          (rate) =>
            parseFloat(rate.cubic_meter_size_start) <= newCubicSize &&
            newCubicSize <= parseFloat(rate.cubic_meter_size_end)
        );
    
        if (rate) {
          const estimatedTime = timeStringToMinutes(rate.time_required);
          const costPerHour = parseFloat(rate.per_hour_rate) || 0;
          const costPerMinute = costPerHour / 60;
    
          let loadingLowTime = timeStringToMinutes(rate.loading_low_time);
          let loadingMediumTime = timeStringToMinutes(rate.loading_medium_time);
          let loadingHighTime = timeStringToMinutes(rate.loading_high_time);
          let unLoadingLowTime = timeStringToMinutes(rate.unloading_low_time);
          let unLoadingMediumTime = timeStringToMinutes(rate.unloading_medium_time);
          let unLoadingHighTime = timeStringToMinutes(rate.unloading_high_time);
          const assembly_disassembly_per_hour_rate= rate.assembly_disassembly_per_hour_rate;

          const loadingOneFlight = timeStringToMinutes(rate.loading_oneflight);
          const loadingSecondFlight = timeStringToMinutes(rate.loading_secondflight);
          const loadingThirdFlight = timeStringToMinutes(rate.loading_thirdflight);
          const unloadingOneFlight = timeStringToMinutes(rate.unloading_oneflight);
          const unloadingSecondFlight = timeStringToMinutes(rate.unloading_secondflight);
          const unloadingThirdFlight = timeStringToMinutes(rate.unloading_thirdflight);
    
          const loadingStairs = pickupFlights || "";
          const unloadingStairs = dropOffFlights || "";
    
          if (loadingStairs) {
            if (loadingStairs.includes("1 Flight")) {
              loadingLowTime += loadingOneFlight;
              loadingMediumTime += loadingOneFlight;
              loadingHighTime += loadingOneFlight;
            } else if (loadingStairs.includes("2 Flight")) {
              loadingLowTime += loadingSecondFlight;
              loadingMediumTime += loadingSecondFlight;
              loadingHighTime += loadingSecondFlight;
            } else if (loadingStairs.includes("3+ Flight")) {
              loadingLowTime += loadingThirdFlight;
              loadingMediumTime += loadingThirdFlight;
              loadingHighTime += loadingThirdFlight;
            }
          }
    
          if (unloadingStairs && unloadingStairs !== "null") {
            if (unloadingStairs.includes("1 Flight")) {
              unLoadingLowTime += unloadingOneFlight;
              unLoadingMediumTime += unloadingOneFlight;
              unLoadingHighTime += unloadingOneFlight;
            } else if (unloadingStairs.includes("2 Flight")) {
              unLoadingLowTime += unloadingSecondFlight;
              unLoadingMediumTime += unloadingSecondFlight;
              unLoadingHighTime += unloadingSecondFlight;
            } else if (unloadingStairs.includes("3+ Flight")) {
              unLoadingLowTime += unloadingThirdFlight;
              unLoadingMediumTime += unloadingThirdFlight;
              unLoadingHighTime += unloadingThirdFlight;
            }
          }
    
          const loadingLowTiming = minutesToTimeString(loadingLowTime);
          const loadingMediumTiming = minutesToTimeString(loadingMediumTime);
          const loadingHighTiming = minutesToTimeString(loadingHighTime);
          const unLoadingLowTiming = minutesToTimeString(unLoadingLowTime);
          const unLoadingMediumTiming = minutesToTimeString(unLoadingMediumTime);
          const unLoadingHighTiming = minutesToTimeString(unLoadingHighTime);
    
          const totalLoadingLow = (loadingLowTime * costPerMinute).toFixed(2);
          const totalLoadingMedium = (loadingMediumTime * costPerMinute).toFixed(2);
          const totalLoadingHigh = (loadingHighTime * costPerMinute).toFixed(2);
    
          const totalUnloadingLow = (unLoadingLowTime * costPerMinute).toFixed(2);
          const totalUnloadingMedium = (unLoadingMediumTime * costPerMinute).toFixed(2);
          const totalUnloadingHigh = (unLoadingHighTime * costPerMinute).toFixed(2);
    
          const totalMinutes = convertToMinutes(setDuration || "0");
          const totalDurationCost = (totalMinutes * costPerMinute).toFixed(2);
    
          const bookingFee = parseFloat(rate.booking_fee) || 0;
    
          let totalBackToBaseTimeMinutes = convertToMinutes(setBaseTime || "0");
          if (totalBackToBaseTimeMinutes < 30) {
            totalBackToBaseTimeMinutes = 30;
          }
          const grandTotalBackToBase = (
            totalBackToBaseTimeMinutes * costPerMinute
          ).toFixed(2);
    
          const grandTotalLow = (
            parseFloat(totalLoadingLow) +
            parseFloat(totalUnloadingLow) +
            parseFloat(totalDurationCost) +
            parseFloat(bookingFee) +
            parseFloat(grandTotalBackToBase)
          ).toFixed(2);
          const grandTotalMedium = (
            parseFloat(totalLoadingMedium) +
            parseFloat(totalUnloadingMedium) +
            parseFloat(totalDurationCost) +
            parseFloat(bookingFee) +
            parseFloat(grandTotalBackToBase)
          ).toFixed(2);
          const grandTotalHigh = (
            parseFloat(totalLoadingHigh) +
            parseFloat(totalUnloadingHigh) +
            parseFloat(totalDurationCost) +
            parseFloat(bookingFee) +
            parseFloat(grandTotalBackToBase)
          ).toFixed(2);
    
          // Update state with calculated values
          setTotalLoadingLow(totalLoadingLow);
          setTotalLoadingMedium(totalLoadingMedium);
          setTotalLoadingHigh(totalLoadingHigh);
          setTotalUnloadingLow(totalUnloadingLow);
          setTotalUnloadingMedium(totalUnloadingMedium);
          setTotalUnloadingHigh(totalUnloadingHigh);
    
          setLoadingLowTiming(loadingLowTiming);
          setLoadingMediumTiming(loadingMediumTiming);
          setLoadingHighTiming(loadingHighTiming);
          setUnLoadingLowTiming(unLoadingLowTiming);
          setUnLoadingMediumTiming(unLoadingMediumTiming);
          setUnLoadingHighTiming(unLoadingHighTiming);
    
          setTotalDurationCost(totalDurationCost);
          setGrandTotalHigh(grandTotalHigh);
          setGrandTotalMedium(grandTotalMedium);
          setGrandTotalLow(grandTotalLow);
          setCostPerHour(costPerHour);
          setBookingFee(bookingFee);
          setGrandTotalBackToBase(grandTotalBackToBase);
          setMoversCount(rate.movers_needed);
          setTimeRequired(rate.time_required);
          setTotalCost((rate.time_required * rate.per_hour_rate).toFixed(2));
    
          return {
            estimatedTime: minutesToTimeString(estimatedTime),
            totalLoadingLow,
            totalLoadingMedium,
            totalLoadingHigh,
            totalUnloadingLow,
            totalUnloadingMedium,
            totalUnloadingHigh,
            loadingLowTiming,
            loadingMediumTiming,
            loadingHighTiming,
            unLoadingLowTiming,
            unLoadingMediumTiming,
            unLoadingHighTiming,
            totalDurationCost,
            grandTotalHigh,
            grandTotalMedium,
            grandTotalLow,
            costPerHour,
            bookingFee,
            grandTotalBackToBase,
          };
        } else {
          console.log("No rate found for the calculated cubic size.");
          setMoversCount(0);
          setTotalCost(0);
          setGrandTotalLow(0);
          setGrandTotalMedium(0);
          setGrandTotalHigh(0);
        }
      } catch (error) {
        console.error("Error calculating estimate:", error);
      }
    };
    

    const convertToMinutes = (timeString) => {
      if (!timeString || typeof timeString !== "string") {
       // console.warn("Invalid time format:", timeString);
        return 0;
      }

      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const timeStringToMinutes = (timeString) => {
      if (typeof timeString === "number") {
        return timeString * 60;
      }
      if (typeof timeString === "string") {
        const [hoursStr, minutesStr] = timeString.split(":");
        const hours = parseFloat(hoursStr) || 0;
        const minutes = parseFloat(minutesStr) || 0;
        return hours * 60 + minutes;
      }
      //console.warn("Invalid time format:", timeString);
      return 0;
    };

    const minutesToTimeString = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}`;
    };

    calculateEstimate();
  }, [
    bedrooms,
    livingAreas,
    furnitureVolume,
    outsideMove,
    potPlants,
    vehicles,
    rateData,
  ]);
  

  return (
    <div>
      <div className="access-properties">
        <div className="container ">
          <div
            className="nav d-flex justify-content-around"
            role="tablist"
            aria-orientation="vertical"
          >
            <a className="nav-link ">Location</a>
            <a className="nav-link active">Move Size</a>
            <a className="nav-link">Date & Time</a>
            <a className="nav-link">Details</a>
            <a className="nav-link">My Quote</a>
          </div>
          <h3 className="text-center">Let's quickly estimate the size.</h3>
          <p style={{ textAlign: "center " }}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlebackNavigate();
              }}
              style={{ textDecoration: "none" }}
            >
              Change the method for estimation.
            </a>
          </p>
          <div className="bg__size">
            <h2 className="form-title">
            Provide details about the property we’re picking up from.
            </h2>
            <div className="form-group mb-0">
              <label className="form-label font-weight-bold">
              What type of property is it?
              </label>
            </div>
            <div className="form_house ">
              <p className="mb-1">Single-level house.</p>
              <a
                href="#"
                className="text-decoration-none"
                onClick={handleGoBack}
              >
                Change the property type to single-level house.
              </a>
            </div>

            <div className="form-group">
              <label className="form-label font-weight-bold text-left">
                How many bedrooms (with furniture), add 0.5 for a study
              </label>
            </div>
            <div>
              <select
                className="form-select w-50"
                value={bedrooms}
                onChange={(e) => setBedrooms(e.target.value)}
              >
                <option value="">Select</option>
                <option value="0.5">0.5</option>
                <option value="1">1</option>
                <option value="1.5">1.5</option>
                <option value="2">2</option>
                <option value="2.5">2.5</option>
                <option value="3">3</option>
                <option value="3.5">3.5</option>
                <option value="4">4</option>
                <option value="4.5">4.5</option>
                <option value="5+">5 +</option>
              </select>
              {validationErrors.bedrooms && (
                <div className="text-danger">{validationErrors.bedrooms}</div>
              )}
            </div>

            <div>
              <div className="form-group">
                <label className="form-label pt-2 font-weight-bold">
                  How many living areas with couches (?)
                </label>
              </div>
              <div className="radio-group size__radio">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={livingAreas === "1"}
                    onChange={(e) => setLivingAreas(e.target.value)}
                  />{" "}
                  1
                </label>
                <label>
                  <input
                    type="radio"
                    value="2"
                    checked={livingAreas === "2"}
                    onChange={(e) => setLivingAreas(e.target.value)}
                  />{" "}
                  2
                </label>
                <label>
                  <input
                    type="radio"
                    value="3"
                    checked={livingAreas === "3"}
                    onChange={(e) => setLivingAreas(e.target.value)}
                  />{" "}
                  3+
                </label>
              </div>
              {validationErrors.livingAreas && (
                <div className="text-danger">
                  {validationErrors.livingAreas}
                </div>
              )}
            </div>

            <div>
              <div className="form-group">
                <label className="form-label pt-2 font-weight-bold">
                  What best describes the volume of furniture{" "}
                  <span>
                    <i>
                      <u>inside</u>
                    </i>
                  </span>{" "}
                  your home (?)
                </label>
              </div>
              <div className="radio-group size__radio">
                <label>
                  <input
                    type="radio"
                    value="Lightly furnished"
                    checked={furnitureVolume === "Lightly furnished"}
                    onChange={(e) => setFurnitureVolume(e.target.value)}
                  />{" "}
                  Lightly furnished
                </label>
                <label>
                  <input
                    type="radio"
                    value="Moderately furnished"
                    checked={furnitureVolume === "Moderately furnished"}
                    onChange={(e) => setFurnitureVolume(e.target.value)}
                  />{" "}
                  Moderately furnished
                </label>
                <label>
                  <input
                    type="radio"
                    value="Heavily furnished"
                    checked={furnitureVolume === "Heavily furnished"}
                    onChange={(e) => setFurnitureVolume(e.target.value)}
                  />{" "}
                  Heavily furnished
                </label>
              </div>
              {validationErrors.furnitureVolume && (
                <div className="text-danger">
                  {validationErrors.furnitureVolume}
                </div>
              )}
            </div>

            <div>
              <div className="form-group">
                <label className="form-label pt-2 font-weight-bold">
                  How much to move from{" "}
                  <span>
                    <i>
                      <u>outside</u>
                    </i>
                  </span>{" "}
                  your home
                </label>
              </div>
              <div className="radio-group size__listing">
                <label>
                  <input
                    type="radio"
                    value="Nothing"
                    checked={outsideMove === "Nothing"}
                    onChange={(e) => setOutsideMove(e.target.value)}
                  />{" "}
                  Nothing
                  <br />
                  <span className="example-text">
                  There are no outdoor furniture or garage items.
                  </span>
                </label>
                <hr />
                <label>
                  <input
                    type="radio"
                    value="Small"
                    checked={
                      outsideMove ===
                      "Small"
                    }
                    onChange={(e) => setOutsideMove(e.target.value)}
                  />{" "}
                  Small outdoor furniture and/or basic garage items.
                  <br />
                  <span className="example-text">
                    E.g. Includes a small outdoor table, chairs, a BBQ, and up to 5 boxes.
                  </span>
                </label>
                <hr />
                <label>
                  <input
                    type="radio"
                    value="Large"
                    checked={
                      outsideMove === "Large"
                    }
                    onChange={(e) => setOutsideMove(e.target.value)}
                  />{" "}
                  Outdoor furniture and a well-used garage.
                  <br />
                  <span className="example-text">
                    E.g. Outdoor furniture, a BBQ, shelving, bikes, a lawnmower, and 10-20 boxes.
                  </span>
                </label>
                <hr />
                <label>
                  <input
                    type="radio"
                    value="Extra Large"
                    checked={
                      outsideMove === "Extra Large"
                    }
                    onChange={(e) => setOutsideMove(e.target.value)}
                  />{" "}
                 Outdoor furniture and an overloaded garage.
                  <br />
                  <span className="example-text">
                  If it’s not listed here, it’s considered part of the overload.
                  </span>
                </label>
              </div>
              {validationErrors.outsideMove && (
                <div className="text-danger">
                  {validationErrors.outsideMove}
                </div>
              )}
            </div>

            <div>
              <div className="form-group">
                <label className="form-label pt-2 font-weight-bold">
                  How many pot plants to be moved?
                </label>
              </div>
              <div className="radio-group size__radio">
                <label>
                  <input
                    type="radio"
                    value="None"
                    checked={potPlants === "None"}
                    onChange={(e) => setPotPlants(e.target.value)}
                  />{" "}
                  None
                </label>
                <label>
                  <input
                    type="radio"
                    value="1 - 5"
                    checked={potPlants === "1 - 5"}
                    onChange={(e) => setPotPlants(e.target.value)}
                  />{" "}
                  1-5
                </label>
                <label>
                  <input
                    type="radio"
                    value="6 - 10"
                    checked={potPlants === "6 - 10"}
                    onChange={(e) => setPotPlants(e.target.value)}
                  />{" "}
                  6-10
                </label>
                <label>
                  <input
                    type="radio"
                    value="11+"
                    checked={potPlants === "11+"}
                    onChange={(e) => setPotPlants(e.target.value)}
                  />{" "}
                  11+
                </label>
                
              </div>
              {validationErrors.potPlants && (
                <div className="text-danger">{validationErrors.potPlants}</div>
              )}
            </div>

            {error && <div className="text-danger">{error}</div>}
            <hr />
            <div className="info-container d-flex align-items-center justify-content-between">
              <div className="info-item d-flex align-item-center justify-content-center">
                <FontAwesomeIcon
                  icon={faCube}
                  className="info-icon"
                  style={{ marginRight: "10px", color: "#40e550" }}
                />
                <p className="info-text">
                  {cubicSize
                    ? ` ${cubicSize} cubic meters`
                    : "Details needed to calculate."}
                </p>
              </div>
              <div className="info-item d-flex align-item-center justify-content-center">
                <FontAwesomeIcon
                  icon={faTruck}
                  className="info-icon"
                  style={{ marginRight: "10px", color: "#40e550" }}
                />
                <p className="info-text">
                  {vehicleCount
                    ? ` ${vehicleCount}`
                    : "Calculated from cubic size."}
                </p>
              </div>
              <div className="info-item d-flex align-item-center justify-content-center">
                <FontAwesomeIcon
                  icon={faUser}
                  className="info-icon"
                  style={{ marginRight: "10px", color: "#40e550" }}
                />
                <p className="info-text">
                  {moversCount
                    ? ` ${moversCount}`
                    : "Movers count based on cubic size."}
                </p>
              </div>
              {/* <div className="info-item d-flex align-item-center justify-content-center" >
                <FontAwesomeIcon icon={faDollarSign} className="info-icon" />
                <p className="info-text">
                  {totalCost
                    ? ` $${totalCost}`
                    : "Total cost will be calculated based on cubic size and rate."}
                </p>
              </div> */}
              <div className="info-item d-flex align-item-center justify-content-center">
                <FontAwesomeIcon
                  icon={faClock}
                  className="info-icon"
                  style={{ marginRight: "10px", color: "#40e550" }}
                />
                <p className="info-text">
                  {timeRequired
                    ? ` ${timeRequired} hours`
                    : "Time based on cubic size and rate."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg__footerContainer">
        <div className="footer-container">
          <button
            type="submit"
            onClick={handleSubmit}
            className={`next_link ${buttonDisabled ? "disabled" : ""}`}
            disabled={buttonDisabled}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
}

export default Size;
