import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Completed from "./Completed";
import Cancelled from "./Cancelled";
import Schedule from "./Schedule";
import "../ViewInventory/Viewinventory.css";
import "../Profile/completed.css";
import Accordion from "react-bootstrap/Accordion";
import HandleSchedule from "./HandleSchedule";
import QuickSchedule from "./QuickSchedule";
import DetailSchedule from "./DetailSchedule";
import HandleCancelled from "./HandleCancelled";
import QuickCancelled from "./QuickCancelled";
import DetailCancelled from "./DetailCancelled";
import HandleCompleted from "./HandleCompleted";
import QuickCompleted from "./QuickCompleted";
import DetailCompleted from "./DetailCompleted";

const Prfilescreen = () => {
  // State to manage selected data
  const [selectedData, setSelectedData] = useState("");
  const [accordionExpanded, setAccordionExpanded] = useState("");
  // Handle button click
  const handleClick = (data) => {
    setSelectedData(data);
  };

  // Function to render content based on selected data
  const renderContent = () => {
    switch (selectedData) {
      case "Data for Button 1":
        return (
          <div>
            <DetailCompleted />
          </div>
        );
      case "Data for Button 2":
        return (
          <div>
            <QuickCompleted />
          </div>
        );
      case "Data for Button 3":
        return (
          <div>
            <HandleCompleted />
          </div>
        );
        case "Data for Button 4":
          return (
            <div>
              <DetailCancelled />
            </div>
          );
        case "Data for Button 5":
          return (
            <div>
              <QuickCancelled />
            </div>
          );
        case "Data for Button 6":
          return (
            <div>
              <HandleCancelled />
            </div>
          );
          case "Data for Button 7":
            return (
              <div>
                <DetailSchedule />
              </div>
            );
          case "Data for Button 8":
            return (
              <div>
                <QuickSchedule />
              </div>
            );
          case "Data for Button 9":
            return (
              <div>
                <HandleSchedule />
              </div>
            );
      default:
        return (
          <div>
            <h6 className="fs-4 text-center mb-0">
              Select a Button to See Content.
            </h6>
          </div>
        );
    }
  };

  return (
    <div className="access-properties">
      <h1 className="text-center">Details of your Movers</h1>
      <Container>
        <Row>
          <Col md={3}>
            <div className="d-flex flex-column ">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header> Completed</Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex flex-column ">
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 1")}
                      >
                        Details
                      </Button>
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 2")}
                      >
                        Quick
                      </Button>
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 3")}
                      >
                        Handle
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header> Cancelled </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex flex-column ">
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 4")}
                      >
                        Detail
                      </Button>
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 5")}
                      >
                        Quick
                      </Button>
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 6")}
                      >
                        Handle
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header> Schedule</Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex flex-column ">
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 7")}
                      >
                        Details
                      </Button>
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 8")}
                      >
                        Quick
                      </Button>
                      <Button
                        variant=" "
                        className={`room-button p-3`}
                        onClick={() => handleClick("Data for Button 9")}
                      >
                        Handle
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>

          <Col md={9} className="pt-4 pt-md-0">
            <div
              className="p-3 border bg-light"
              style={{
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              {renderContent()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Prfilescreen;
