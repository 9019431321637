
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import MovingForm from "./Components/Pages/Home/Home";
import CustomFooter from "./Components/Footer/Footer";
import NextPage from "./Components/Pages/Nextpage/Nextpage";
import NextpageFooter from "./Components/NextpageFooter/Nextpagefooter";
import VerticalTimeline from "./Components/Pages/Timeline/Timeline";  
import AccessProperties from "./Components/Pages/TimelineLocation/Location";
import MovingLocation from "./Components/Pages/MovingLocation/MovingLocation";
import Services from "./Components/Pages/Services/Services";
import ContactUs from "./Components/Pages/ContactUs/Contactus";
import Faqs from "./Components/Pages/Faqs/Faqs";
import BecomeMover from "./Components/Pages/Become_Mover/BecomeMover";
import ParentComponent from "./Components/Parent/ParentComponent";
import MoveForm from "./Components/Pages/Date&Time/Date&Time";
import Size from "./Components/Pages/SizeEstimate/Size";
import Searchitem from "./Components/Pages/Searchitem/Searchitem";
import Details from "./Components/Pages/Details/Details";
import Myquote from "./Components/Pages/Myquote/Myquote";
import RoomInventory from "./Components/Pages/ViewInventory/Viewinventory";
import Completed from "./Components/Pages/Profile/Completed";
import Prfilescreen from "./Components/Pages/Profile/Profilescreen";
import Schedule from "./Components/Pages/Profile/Schedule";
import Cancelled from "./Components/Pages/Profile/Cancelled";
import InventoryMyquote from "./Components/Pages/Myquote/InventoryMyquote";
import QuickMyquote from "./Components/Pages/Myquote/QuickMyquote";
import HandlfullMyquote from "./Components/Pages/Myquote/HandfullMyquote";
import DetailCancelled from "./Components/Pages/Profile/DetailCancelled";
import DetailSchedule from "./Components/Pages/Profile/DetailSchedule";
import DetailCompleted from "./Components/Pages/Profile/DetailCompleted";
import HandleCompleted from "./Components/Pages/Profile/HandleCompleted";
import HandleCancelled from "./Components/Pages/Profile/HandleCancelled";
import HandleSchedule from "./Components/Pages/Profile/HandleSchedule";
import QuickCompleted from "./Components/Pages/Profile/QuickCompleted";
import QuickCancelled from "./Components/Pages/Profile/QuickCancelled";
import QuickSchedule from "./Components/Pages/Profile/QuickSchedule";
import QuoteDetail from "./Components/Pages/Profile/QuoteDetail";
import QuateQuick from "./Components/Pages/Profile/QuateQuick";
import HandleQuick from "./Components/Pages/Profile/HandleQuick";
import PaymentSuccess from "./Components/Pages/Payment/PaymentSuccess";
import PaymentFailed from "./Components/Pages/Payment/PaymentFailed ";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<MovingForm />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/becomeamover" element={<BecomeMover />} />
          <Route path="/accessproperties" element={<AccessProperties />} />
          <Route path="/movinglocation" element={<MovingLocation />} />
          <Route path="/size" element={<Size />} />
          <Route path="/searchitem" element={<Searchitem />} />
          <Route path="/movefrom" element={<MoveForm />} />
          <Route path="/details" element={<Details />} />
          <Route path="/myquote" element={<Myquote />} />
          <Route path="/roominventory" element={<RoomInventory />} />
          <Route path="/handlfullmyquote" element={<HandlfullMyquote />} />
          <Route path="/inventorymyquote" element={<InventoryMyquote />} />
          <Route path="/quickmyquote" element={<QuickMyquote />} />
          <Route path="/completed" element={<Completed />} />
          <Route path="/profile" element={<Prfilescreen />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/cancelled" element={<Cancelled />} />
          <Route path="/detailcancelled" element={<DetailCancelled />} />
          <Route path="/detailschedule" element={<DetailSchedule />} />
          <Route path="/detailcompleted" element={<DetailCompleted />} />
          <Route path="/handlecompleted" element={<HandleCompleted />} />
          <Route path="/handlecancelled" element={<HandleCancelled />} />
          <Route path="/handleschedule" element={<HandleSchedule />} />
          <Route path="/quickcompleted" element={<QuickCompleted />} />
          <Route path="/quickcancelled" element={<QuickCancelled />} />
          <Route path="/quickschedule" element={<QuickSchedule />} />
          <Route path="/quotedetail" element={<QuoteDetail />} />
          <Route path="/quatequick" element={<QuateQuick />} />
          <Route path="/handlequick" element={<HandleQuick />} />
          <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
          <Route path="/PaymentFailed" element={<PaymentFailed />} />
        </Routes>
        <CustomFooter />
      </BrowserRouter>
    </div>
  );
}


export default App;