import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/HomePage.css";
import LocalMoves from "../../Assests/local_moves.png";
import country from "../../Assests/country.png";
import interstate from "../../Assests/street.png";
import office from "../../Assests/office.png";
import manager from "../../Assests/manager.png";
import wallclock from "../../Assests/wall-clock.png";
import trash from "../../Assests/trash.png";
import unpacking from "../../Assests/unpacking.png";
import mobile from "../../Assests/mobile.png";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, description }) => (
  <div className="custom-service-card">
    <div className="custom-icon mb-3">
      <img src={icon} alt={title} className="img-fluid" />
    </div>
    <h5 className="custom-title mb-3">{title}</h5>
    <p className="custom-description">{description}</p>
  </div>
);

export default function HomePage() {
  return (
    <div className="container my-5">
      <h2 className="text-center mb-5">Full Service Moving</h2>
      <div className="row">
        <div className="col-md-4">
          <ServiceCard
            icon={LocalMoves}
            title="Local Moves"
            description="Choose expert, hand-picked removalists for any move, from a small apartment to a large home, at your convenience."
          />
        </div>
        <div className="col-md-4">
          <ServiceCard
            icon={country}
            title="Country Moves"
            description="Moving to or from rural areas? We serve within a four-hour radius of Sydney & Melbourne. Contact us today."
          />
        </div>
        <div className="col-md-4">
          <ServiceCard
            icon={interstate}
            title="Interstate Moves"
            description="From arrival times to storage, we handle it all with transparent pricing and dedicated coordinators."
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <ServiceCard
            icon={office}
            title="Office Moves"
            description="We understand how critical a smooth move is for your business."
          />
        </div>
        <div className="col-md-4">
          <ServiceCard
            icon={manager}
            title="Labour Only & Special Projects"
            description="Unique move? Let us customize a package for you. We're here to help."
          />
        </div>
        <div className="col-md-4">
          <ServiceCard
            icon={wallclock}
            title="Last Minute Moves"
            description="Need an urgent move? Call 1300 988 114 now for fast, affordable service."
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <ServiceCard
            icon={trash}
            title="Rubbish Removal"
            description="Got excess rubbish? Start by visiting your nearest tip. We’ll add the tip fees to your job—simple and easy!"
          />
        </div>
        <div className="col-md-4">
          <ServiceCard
            icon={unpacking}
            title="Packing & Unpacking"
            description="Relax with our professional packers. We provide all materials (boxes, bubble wrap, etc.). Contact us today."
          />
        </div>
        <div className="col-md-4">
          <ServiceCard
            icon={mobile}
            title="Utility Connections"
            description="Make your move simple and save on gas and electricity with our free utility connection."
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Link to="/next-page" className="Service_pricing">
          Get Instant Pricing
        </Link>
        <a href="tel:607 722 2030" className="Service_call">
          607 722 2030
        </a>
      </div>
    </div>
  );
}
