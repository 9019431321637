import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Testimonial/Testimonial.css";
import profileimg from "../../Assests/Testimonial1.webp";

export default function Testimonials() {
  const testimonials = [
    {
      name: "Michael",
      company: "MDS Manufacturing",
      text: "Seamless moving process with expert packers and timely delivery. Highly professional and hassle-free service",
      image: profileimg,
    },
    {
      name: "Diane",
      company: "ABC Rentals",
      text: "Custom moving solutions tailored to your needs, ensuring a smooth and efficient relocation experience.",
      image: profileimg,
    },
    {
      name: "Allison",
      company: "Grand Party Rental",
      text: "Exceptional service at competitive rates. Reliable, careful movers who value your time and belongings.",
      image: profileimg,
    },
  ];

  return (
    <div className="testimonial-section text-white">
      <Container>
        <Row className="mb-4 p-3 p-md-0">
          <Col md={6} className="d-flex flex-column justify-content-center text-center text-md-start">
            <h3 className="Testminioal_heading">
              <span className="highlight">Connect</span> with <br /> other{" "}
              <span className="highlight">members</span>
            </h3>
            <p className="fs-5 text-justify">
            Connect with fellow members and experience a community that values collaboration and support. Engage in meaningful discussions, share insights, and grow together in a vibrant network.
            </p>
          </Col>
          <Col md={6}>
            <div className="carousel-container">
              <Carousel controls={false} indicators={true}>
                {testimonials.map((testimonial, index) => (
                  <Carousel.Item key={index}>
                    <div className="testimonial-card text-dark p-4 rounded position-relative">
                      <i className="fas fa-quote-left quote-icon-card"></i>
                      <Row className="align-items-center">
                        <Col xs={12} sm={4} className="text-center mb-3 mb-sm-0">
                          <img
                            className="d-block w-100 mx-auto"
                            src={testimonial.image}
                            alt={testimonial.name}
                          />
                        </Col>
                        <Col xs={12} sm={8}>
                          <blockquote className="blockquote mb-4">
                            <p className="mb-0">{testimonial.text}</p>
                          </blockquote>
                          <footer className="blockquote-footer">
                            <strong>{testimonial.name}</strong>{" "}
                            <cite title="Source Title">
                              {testimonial.company}
                            </cite>
                          </footer>
                        </Col>
                      </Row>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
