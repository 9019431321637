import React, { useState } from 'react';
import axios from 'axios';

const QuoteDetails = ({ enquiryId }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [detailedData, setDetailedData] = useState(null);
  const [data, setData] = useState({
    enquirydetails: null,
    handelItems: [],
  });
  const toggleDetails = async () => {
    if (!detailsVisible) {
      try {
        const response = await axios.get(`https://demo.webwideit.solutions/cbd-instamove/public/api/sheduled-view-handel-estimate-booking/${enquiryId}`);
        if (response.data.success) {
          setDetailedData(response.data);
          setData({
            enquirydetails: response.data.enquirydetails,
            handelItems: response.data.handelItems,
          });
        } else {
          console.error("Failed to fetch detailed data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
      }
    }
    setDetailsVisible(!detailsVisible);
  };

  return (
    <div className="quote-details">
      {/* Add your existing quote details rendering code here */}
      
      <button onClick={toggleDetails} className="btn btn-primary">
        {detailsVisible ? 'Hide Details' : 'Show Details'}
      </button>

      {detailsVisible && detailedData && (
        <div className="row mt-3">
          <div className="col-12">
            <div className="inventory-container">
              <h2 style={{ color: "#8e4fe9" }} className="fs-3">Inventory List</h2>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Volume</th>
                  </tr>
                </thead>
                <tbody>
                  {data.handelItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.item_name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.volume}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteDetails;