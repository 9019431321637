import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import "../Home/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import HomePage from "./HomePage";
import Testimonial from "../Testimonial/Testimonial";
import IncrementNumber from "../IncrementNumber/IncrementNumber";
import { useNavigate } from "react-router-dom";
import bgImage1 from "../../Assests/image_home.webp";
import bgImage2 from "../../Assests/image_homea.webp";
import bgImage3 from "../../Assests/image_homeb.webp";
import bgImagea from "../../Assests/bgImagea.webp";
import banner from "../../Assests/banner.webp"
const images = [banner];
const googleMapsApiKey = "AIzaSyDUkMVVi6QljBfyIuIVsE8MbkgEzu9C0P0";

export default function MovingForm() {
  const [currentImage, setCurrentImage] = useState(0);
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      script.onload = () => {
        if (window.google && window.google.maps) {
          new window.google.maps.places.Autocomplete(pickupRef.current, {
            types: ["geocode"],
          });
          new window.google.maps.places.Autocomplete(dropoffRef.current, {
            types: ["geocode"],
          });
        }
      };
      script.onerror = () => {
        console.error("Error loading Google Maps script");
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  const handleQuoteRequest = useCallback(async () => {
    const fromLocation = pickupRef.current?.value || "";
    const toLocation = dropoffRef.current?.value || "";

    // Validation check
    if (!fromLocation || !toLocation) {
      setErrorMessage("Please fill in both pickup and drop-off locations.");
      return;
    }

    try {
      const response = await fetch(
        "https://demo.webwideit.solutions/cbd-instamove/public/api/new-enquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromlocation: fromLocation,
            tolocation: toLocation,
          }),
        }
      );

      const data = await response.json();
      console.log("Quote data:", data);

      if (data.success) {
        // Save data to local storage
        localStorage.setItem("enquiry_id", data.enquiry_id);
        localStorage.setItem("from_location", fromLocation);
        localStorage.setItem("to_location", toLocation);

        console.log(
          "Enquiry ID, from location, and to location saved to local storage"
        );
        navigate("/accessproperties");
        window.location.reload();
      } else {
        console.error("Failed to save enquiry:", data.message);
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, [navigate]);
  return (
    <>
      <div className="carousel-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-slide ${
              index === currentImage ? "active" : ""
            }`}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
        <div className="moving-form">
          <h1 class="small-heading fs-4 font-weight-bold">Start your better move.</h1>

          <div className="form-group-container">
            <div
              className="form-group mb-0"
              style={{ justifyContent: "center" }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              <input
                style={{
                  width: "calc(100% - 40px)", // Adjust width to fit the container
                  padding: "15px 15px 15px 40px", // Ensure padding accounts for the icon
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxSizing: "border-box", // Include padding and border in element's total width and height
                  fontSize: "1rem",
                }}
                type="text"
                placeholder="Picking up from"
                ref={pickupRef}
              />
            </div>
            <div
              className="form-group mb-0"
              style={{ justifyContent: "center" }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              <input
                style={{
                  width: "calc(100% - 40px)", // Adjust width to fit the container
                  padding: "15px 15px 15px 40px", // Ensure padding accounts for the icon
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxSizing: "border-box", // Include padding and border in element's total width and height
                  fontSize: "1rem",
                }}
                type="text"
                placeholder="Dropping off at"
                ref={dropoffRef}
              />
            </div>
            {/* Error message display */}
            {errorMessage && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "1rem" }}
              >
                {errorMessage}
              </p>
            )}
            <Link
              to="/accessproperties"
              className="quote-button"
              onClick={(e) => {
                e.preventDefault();
                handleQuoteRequest();
              }}
            >
              Get an Instant Quote
            </Link>
          </div>
        </div>
      </div>
      <HomePage />
      {/* <IncrementNumber /> */}
      <Testimonial />
    </>
  );
}
