import React, { useEffect, useState } from 'react';
import logos from '../../Assests/Skilled-Movers-Logo.png';

const PaymentFailed = () => {
    const [showRetry, setShowRetry] = useState(false);

    useEffect(() => {
        const detailsEstimate = localStorage.getItem('DetailsEstimete');
        const quickEstimate = localStorage.getItem('QuickEstimete');
        const handleEstimate = localStorage.getItem('HandleEstimete');

        if (detailsEstimate === '1' || quickEstimate === '2' || handleEstimate === '3') {
            setShowRetry(true);
        } else {
            setShowRetry(false);
        }
    }, []);
    const handleRetryPayment = () => {
        const detailsEstimate = localStorage.getItem('DetailsEstimete');
        const quickEstimate = localStorage.getItem('QuickEstimete');
        const handleEstimate = localStorage.getItem('HandleEstimete');
   
        if (detailsEstimate === '1') {
          localStorage.removeItem('DetailsEstimete');
          localStorage.removeItem('QuickEstimete');
          localStorage.removeItem('HandleEstimete');
          window.location.href = '/inventorymyquote';

        } else if (quickEstimate === '2') {
          localStorage.removeItem('DetailsEstimete');
          localStorage.removeItem('QuickEstimete');
          localStorage.removeItem('HandleEstimete');
          window.location.href = '/quickmyquote';

        } else if (handleEstimate === '3') {
          localStorage.removeItem('DetailsEstimete');
          localStorage.removeItem('QuickEstimete');
          localStorage.removeItem('HandleEstimete');
          window.location.href = '/handlfullmyquote';

        } 
    };
   
    
    const handleGoHome = () => {
        localStorage.removeItem('DetailsEstimete');
        localStorage.removeItem('QuickEstimete');
        localStorage.removeItem('HandleEstimete');
        window.location.href = '/';  

    };

    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f4f8',
        padding: '20px',
    };

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    };

    const messageStyle = {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#dc3545',  // Red color for failure
        marginBottom: '20px',
    };

    const iconStyle = {
        fontSize: '100px',
        color: '#dc3545',  // Red color for failure
        marginBottom: '20px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        gap: '10px',  // Space between buttons
        marginTop: '30px',
    };

    const buttonStyle = {
        padding: '12px 24px',  // Increased padding for the button
        fontSize: '18px',  // Increased font size for the button
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const retryButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#007bff',
    };

    const homeButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#28a745',  // Green color for the home button
    };

    const topIconStyle = {
        position: 'absolute',
        top: '-20px',
        right: '50%',
        transform: 'translateX(50%)',
        fontSize: '40px',
        color: '#007bff',
    };

    return (
        <div style={cardContainerStyle}>
            <img src={logos} alt="Logo" width="15%" style={{ marginBottom: '20px' }} />
            <div style={cardStyle}>
                <div style={topIconStyle}>
                    {/* Optional top icon can be placed here */}
                </div>
                <div style={iconStyle}>
                    <span role="img" aria-label="cross">❌</span>
                </div>
                <div style={messageStyle}>Oops! Payment Failed</div>
                <div style={buttonContainerStyle}>
                   
                {showRetry ? (
                        <>
                            <button style={retryButtonStyle} onClick={handleRetryPayment}>
                                Retry Payment
                            </button>
                            <button style={homeButtonStyle} onClick={handleGoHome}>
                                Go Back Home
                            </button>
                        </>
                    ) : (
                        <button style={homeButtonStyle} onClick={handleGoHome}>
                            Go Back Home
                        </button>
                    )}
                </div>
              
               
                  
            </div>
        </div>
    );
};

export default PaymentFailed;
