import React, { useState } from 'react';
import AccessProperties from '../Pages/TimelineLocation/Location';
import MovingLocation from '../Pages/MovingLocation/MovingLocation';
import Details from "../Pages/Details/Details"; // Assuming Details contains all relevant forms
import Myquote from '../Pages/Myquote/Myquote';

const ParentComponent = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    console.log(`Switching to tab: ${tab}`);
    setActiveTab(tab);
  };

  const handleNextClick = () => {
    console.log(`Current active tab: ${activeTab}`);
    switch (activeTab) {
      case "tab1":
        setActiveTab("tab2");
        console.log("Switching to tab2");
        break;
      case "tab2":
        setActiveTab("tab3");
        console.log("Switching to tab3");
        break;
      case "tab3":
        setActiveTab("tab4");
        console.log("Switching to tab4");
        break;
      case "tab4":
        setActiveTab("tab5");
        console.log("Switching to tab5");
        break;
      case "tab5":
        setActiveTab("tab1");
        console.log("Switching to tab1");
        break;
      default:
        setActiveTab("tab1");
        console.log("Switching to tab1");
        break;
    }
  };

  return (
    <>
      <div className="tab-content">
        {activeTab === "tab1" && <AccessProperties />}
        {activeTab === "tab2" && <MovingLocation />}
        {activeTab === "tab3" && <Details />} {/* Assuming this handles Date & Time */}
        {activeTab === "tab4" && <Details />} {/* Assuming this handles Details */}
        {activeTab === "tab5" && <Myquote />}
      </div>

      <ChildComponent onNextClick={handleNextClick} />
    </>
  );
};

const ChildComponent = ({ onNextClick }) => {
  return (
    <div className="bg__footerContainer">
      <div className="footer-container">
        <button type="button" className="next_link" onClick={onNextClick}>
          NEXT
        </button>
      </div>
    </div>
  );
};

export default ParentComponent;
